<template>
  <div class="project-floor-plans-detail__header">
    <div v-if="isLoading" class="row">
      <div class="col-12 md-col-6 row">
        <div class="thumbnail m-2">
          <div class="skeleton d-block w-100 h-100"></div>
        </div>
        <div class="my-2" style="flex-grow: 1">
          <div class="skeleton w-50 d-block"></div>
          <div class="skeleton w-50 d-block my-1"></div>

          <h2 class="skeleton"></h2>

          <div class="col-12 mt-1">
            <p class="w-50 skeleton mb-1"></p>
          </div>

          <div>
            <p class="sale-price skeleton w-75"></p>
            <p class="sale-price-per-unit skeleton w-75 mt-1"></p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-12 md-col-6 row">
        <img
          class="thumbnail m-2"
          :src="$getFileURL(floorPlan.thumbnail[0])"
          :alt="floorPlan.thumbnail.name"
          @error="$setAltPhoto"
          loading="lazy"
        />
        <div class="header-info m-2 sm-mx-0">
          <div class="d-block">
            <component :is="projectTitleComponent" :to="projectRoute">
              {{ floorPlan.project.name }}
            </component>
          </div>
          <div class="d-block">
            <component class="my-1" :is="unitTypeComponent" :to="unitTypeRoute">
              {{ floorPlan.projectUnitType.name }}
            </component>
          </div>

          <h2>{{ floorPlan.name }}</h2>

          <div class="col-12 mt-1">
            <p v-if="floorPlan.lotType" class="lot-type mb-1">
              {{ floorPlan.lotType.name }}
            </p>
          </div>

          <div v-if="floorPlan.salePrice != 0">
            <p class="sale-price">
              RM{{ numberWithCommas(parseFloat(floorPlan.salePrice)) }}
            </p>
            <p class="sale-price-per-unit">
              RM{{ numberWithCommas(floorPlan.salePricePerUnit) }}/{{
                floorPlan.areaUnitType
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 md-col-6">
        <div class="row p-2">
          <h4 class="my-1">Details</h4>
          <!-- Orientation -->
          <div v-if="!isEmpty(floorPlan.orientation)" class="col-12 mb-1">
            <p class="orientation">
              <i class="far fa-compass mr-1"></i>
              Facing {{ floorPlan.orientation }}
            </p>
          </div>
          <!-- Bedroom Bathroom -->
          <div class="col-12 row bed-and-bath mb-2">
            <p class="mr-3">
              <i class="fas fa-bed mr-1"></i>
              {{ floorPlan.numberOfBedroom }}
              <span v-if="floorPlan.extraBedroom">
                + {{ floorPlan.extraBedroom }}
              </span>
              Bedroom
            </p>
            <p>
              <i class="fas fa-bath mr-1"></i>
              {{ floorPlan.numberOfBathroom }}
              <span v-if="floorPlan.extraBathroom">
                + {{ floorPlan.extraBathroom }}
              </span>
              Bathroom
            </p>
          </div>

          <!-- Land & Built-up -->
          <h4 class="col-12 my-1">Measurement</h4>
          <div class="land d-inline-block col-6 pr-1">
            <p class="font-bold">Land</p>
            <p class="area">
              {{ parseFloat(floorPlan.landArea) }}
              {{ floorPlan.areaUnitType }}
            </p>
            <p v-if="parseFloat(floorPlan.landWidth) !== 0" class="dimension">
              Width: {{ parseFloat(floorPlan.landWidth) }}
              {{ floorPlan.areaUnitType }}
            </p>
            <p v-if="parseFloat(floorPlan.landDepth) !== 0" class="dimension">
              Depth: {{ parseFloat(floorPlan.landDepth) }}
              {{ floorPlan.areaUnitType }}
            </p>
          </div>

          <div class="built-up d-inline-block col-6 pl-1">
            <p class="font-bold">Built-up</p>
            <p class="area">
              {{ parseFloat(floorPlan.builtUpArea) }}
              {{ floorPlan.areaUnitType }}
            </p>
            <p
              v-if="parseFloat(floorPlan.builtUpWidth) !== 0"
              class="dimension"
            >
              Width: {{ parseFloat(floorPlan.builtUpWidth) }}
              {{ floorPlan.areaUnitType }}
            </p>
            <p
              v-if="parseFloat(floorPlan.builtUpDepth) !== 0"
              class="dimension"
            >
              Depth: {{ parseFloat(floorPlan.builtUpDepth) }}
              {{ floorPlan.areaUnitType }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@/utils/string";
import isEmpty from "lodash/isEmpty";
import "@/assets/GlobalStyling/static-css/skeleton.scss";

export default {
  components: {},
  mixins: [],
  props: {
    floorPlan: {
      type: Object,
      required: true
    },
    projectRoute: {
      type: [Object, String]
    },
    unitTypeRoute: {
      type: [Object, String]
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      numberWithCommas,
      isEmpty
    };
  },
  computed: {
    projectTitleComponent() {
      return this.projectRoute ? "router-link" : "p";
    },
    unitTypeComponent() {
      return this.unitTypeRoute ? "router-link" : "p";
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-floor-plans-detail__header {
  .header-info {
    flex-wrap: nowrap;
    @media #{$breakpoint-down-sm} {
      margin-bottom: 0;
      flex-wrap: wrap !important;
    }
  }

  .thumbnail {
    @include image(cover);
    width: 150px;
    height: 150px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000030;
    display: inline-block;
    flex-wrap: nowrap;

    @media #{$breakpoint-down-sm} {
      margin-bottom: 0;
      flex-wrap: wrap !important;
      width: 100%;
      height: 100px;
    }
  }

  h2 {
    @media #{$breakpoint-down-sm} {
      font-size: 18px;
    }
  }
  .sub-context {
    color: #656565;
    font-size: 16px;
    @media #{$breakpoint-down-sm} {
      font-size: 14px;
    }
  }

  .lot-type {
    background: $color-secondary;
    color: white;
    width: fit-content;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .sale-price {
    font-size: 20px;
    font-weight: bold;
    color: $color-accent2;
    word-break: break-word;
  }

  .bed-and-bath p,
  .orientation {
    color: #606060;
    font-size: 14px;
  }

  .area {
    font-size: 14px;
    word-break: break-word;
  }
  .dimension {
    color: #656565;
  }
}
</style>
